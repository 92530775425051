<template>
  <div>
    <b-card
      no-body
      class="mb-0"
    >
      <div class="m-2 mt-3">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Afficher</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="pageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entrées</label>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-input-group size="sm">
                <b-form-input
                  id="filterInput"
                  v-model="filterInput"
                  type="search"
                  placeholder="Saisissez votre recherche"
                  @keyup.enter="applyFilter"
                />
                <b-input-group-append>
                  <b-button
                    @click="applyFilter"
                  >
                    Rechercher
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </div>
          </b-col>
          <b-col
            cols="12"
            md="6"
          />
          <!-- Filter On -->
          <b-col
            cols="12"
            md="6"
          >
            
          </b-col>
        </b-row>

        <!-- Tableau -->
        <b-row>
          <b-col cols="12">
            <b-table
              id="datatables"
              class="mt-4 mb-4"
              :items="Datas"
              :fields="fields"
              stacked="md"
              :per-page="perPage"
              :current-page="currentPage"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :sort-direction="sortDirection"
              :filter="filter"
              :filter-included-fields="filterOn"
              striped
              small
              @filtered="onFiltered"
              @row-clicked="item=>$set(item, '_showDetails', !item._showDetails)"
            >

              <!-- Column: Clients -->
              <template #cell(client)="data">
                <b-button
                  :to="`../clients/viewclientref/${data.value}`"
                  v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                  variant="flat-dark"
                  style="width: 150px; text-align: left;"
                >
                  <feather-icon
                    icon="ExternalLinkIcon"
                    class="mr-50"
                  />
                  <span class="align-middle">{{ data.value }}</span>
                </b-button>
              </template>

              <!-- Column: Errors -->
              <template #cell(error)="data">
                <b-badge :variant="data.value[1]" style="width: 100%;">
                  {{ data.value[0] }}
                </b-badge>
              </template>

              <!-- Column: Actions -->
              <template #cell(actions)="data" >
                <b-button
                  :to="{name: 'viewbackupgestion', params: { id: data.item.key }}"
                  v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                  variant="flat-success"
                  class="btn-icon"
                >
                  <feather-icon icon="PlusCircleIcon" />
                  Détails
                </b-button>
              </template>

              <!-- Template Date -->
              <template #cell(date)="data">
                <b-badge  :style="{'background-color': data.item.date.backgroundcolor, 'color': data.item.date.color, 'width': '100%'}">
                  {{ data.item.date.date }}
                </b-badge>
              </template>

            </b-table>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          />
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BBadge,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BButton,
  BTable,
  BLink,
  BDropdown,
  BDropdownItem,
  BPagination,
  BFormGroup,
  BFormCheckbox,
  BFormCheckboxGroup,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import PouchDB from 'pouchdb'
// import { DateTime } from "luxon"
import moment from 'moment'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BBadge,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BButton,
    BTable,
    BLink,
    BDropdown,
    BDropdownItem,
    BPagination,
    BFormGroup,
    BFormCheckbox,
    BFormCheckboxGroup,
    vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      fields: [
        { key: 'actions', label: '', thStyle: { width: '10px' } },
        { key: 'client', label: 'Client', sortable: true},
        { key: 'error', label: 'Error', sortable: true },
        { key: 'date', label: 'Date', sortable: true },
      ],
      Datas: [],
      isLoading: true,
      drawer: true,
      perPage: 10,
      currentPage: 1,
      totalRows: 1,
      currentPage: 1,
      filterInput: '',
      pageOptions: [10, 25, 50, 100],
      filter: null,
      outlined: true,
      filterOn: ['date'],
      sortBy: 'date',
      sortDirection: 'asc',
      sortDesc: true,
    }
  },
  computed: {
    /**
      * Total no. of records
      */
    rows() {
      return this.items.length
    },
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  watch: {},
  created() {

    this.createdview()
    this.timer = setInterval(this.createdview, 30000);

  },
  mounted() {},
  customButtons: {
    default: true,
  },
  methods: {
    createdview() {
      this.localDB = new PouchDB('backup')
      this.remoteDB = new PouchDB('https://couchdb.placedusite.fr/backup')
      // this.localDB.sync(this.remoteDB, { live: true, retry: true })
      // this.localDB = true
      // this.localDB.allDocs({ include_docs: true, attachments: true, startkey: 'dd49', endkey: 'dd49\ufff0' }, this.setClients);

      this.localDB.sync(this.remoteDB).on('complete', () => {

        this.localDB.allDocs({ include_docs: true, attachments: true }, this.setDatas);
      
      }).on('error', (err) => {
        // boo, we hit an error!
      });  
    },
    setDatas(err, doc) {
      this.Datas= []
      if (err) {
        console.log('err', err)
      }
      console.log('doc', doc)
      for (let i = 0; i < doc.rows.length; i++) {

        if (doc.rows[i].doc.erreur != "") {this.color = "light-danger"}
        else {this.color = "light-primary"}

        const [dateValues, timeValues] = doc.rows[i].doc.date.split(' ')
        const [day, month, year] = dateValues.split('/')
        const [hours, minutes] = timeValues.split(':')
        const seconds = '00'
        const date = new Date(+year, +month - 1, +day, +hours, +minutes, +seconds)
        const datebegin = moment(date, "YYYY-MM-DD")
        const dateend = moment().startOf('day')
        const backgroundcolor = ''
        const color = ''

        if (moment.duration(dateend.diff(datebegin)).asDays() >= 1) {
          backgroundcolor = 'rgba(234, 84, 85, 0.12)'
          color = '#ea5455'
        }
        else {
            backgroundcolor = 'transparent'
            color= '#6e6b7b'
        }

        this.Datas.push({
          key: doc.rows[i].id,
          date: {
            date: doc.rows[i].doc.date,
            backgroundcolor: backgroundcolor,
            color: color
          },
          client: doc.rows[i].doc.client,
          error: [doc.rows[i].doc.erreur, this.color],
        })
      }
      this.totalRows = this.Datas.length
    },
    // formatDateAssigned(value) {
    //   const formattedDate = DateTime.fromISO(
    //     value
    //   ).setLocale('fr').toFormat('dd LLL yyyy à hh:mm')
    //   return formattedDate;
    // },
    fetchToDos() {
      this.localDB.allDocs({ include_docs: true, descending: true }, this.setToDos)
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    applyFilter() {
      this.filter = this.filterInput
    },
  },
}
</script>

<style>
    .btn-primary {
        margin-right: 12px;
    }
    .per-page-selector {
      width: 90px;
    }
    .btNopadding button {
      padding: 0;
    }
    @media (max-width: 767.98px) {
      .table.b-table.b-table-stacked-md > tbody > tr > [data-label]::before {
        text-align: left;
      }
    }
</style>

<style lang="scss">
  @import '~@core/scss/vue/libs/vue-select.scss';
</style>
